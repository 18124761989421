import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { Component, useEffect, useState } from "react";
import Swal from "sweetalert2";
import "../departments/department.css";
import "../branches/branches.css";
import { FormControl, Modal } from "react-bootstrap";
import download from "../../assets/images/icons/Vectordownload.png";
import { toast } from "react-toastify";
import { toastmsg } from "../../Helper";
import delete1 from "../../assets/images/icons/delete.png";
import edit from "../../assets/images/icons/edit.png";
import eye from "../../assets/images/icons/eye.png";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useForm } from "react-hook-form";
import Card from "react-bootstrap/Card";
import mobile from "../../assets/images/icons/mobileicon.svg";
import email from "../../assets/images/icons/emailicon.svg";
import greenedit from "../../assets/images/icons/greenedit.svg";
import branchpic from "../../assets/images/icons/branchimg.png";
import { Multiselect } from "multiselect-react-dropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LoadingOverlay from "react-loading-overlay";

function Branches() {
  const [branches, setBranches] = useState("");
  const [branchList, setBranchList] = useState([]);
  const [add, setAdd] = useState(false);
  const [branchData, setBranchData] = useState([]);
  const [hsImage, setHsImage] = useState("");
  const [editImage, setEditImage] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImage1, setSelectedImage1] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [pageCount, setPageCount] = useState(0);
  const [page, setpage] = useState(0);
  const [branchsList, setBranchsList] = useState({ list: [], id: 0 });
  const [subAdminOpen, setSubAdminOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [bId, setBid] = useState("");
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [branchimg, setBranchimg] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subAdmin, setSubadmin] = useState({
    subAdminEmail: "",
    id: "",
  });

  const [dimensions, setDimensions] = useState({
    latitude: "",
    longitude: "",
  });

  let limit = 10;
  const [view, setView] = useState(false);

  const [editBranchData, setEditBranchData] = useState({
    branchName: "",
    mobileNumber: "",
    email: "",
    address: "",
    lats: "",
    long: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    reset: reset2,
  } = useForm();

  const a_id = localStorage.getItem("adminId");

  useEffect(() => {
    getBranchList();
    geo();
  }, [page, branches]);

  const geo = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      dimensions.latitude = position.coords.latitude;
      dimensions.longitude = position.coords.longitude;
      setDimensions({
        ...dimensions,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
  };

  const getBranchList = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/branchtable/${a_id}/${
          branches == "" ? 0.001 : branches
        }`,
        {
          headers: {
            Authorization: `${process.env.REACT_APP_AUTH_KEY}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status == "success") {
          setBranchData(response.data.data);
          setPageCount(response.data.totalPages);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const handleAdd = () => {
    setAdd(true);
  };

  const handleChange = (e, name) => {
    setEditBranchData({ ...editBranchData, [e.target.name]: e.target.value });
  };

  const handleEdit = async (id) => {
    setBid(id);
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL_NEW}/api/getbranchtoedit/${id}`, {
        headers: {
          Authorization: `${process.env.REACT_APP_AUTH_KEY}`,
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          setEditBranchData({
            branchName: res.data.data[0].branch_name,
            mobileNumber: res.data.data[0].mobilenumber,
            email: res.data.data[0].email,
            address: res.data.data[0].address,
            lats: res.data.data[0].lat,
            long: res.data.data[0].long,
          });
          if (
            res.data.data[0].branch_image !== "" &&
            res.data.data[0].branch_image != null
          ) {
            setSelectedImage1(
              `${process.env.REACT_APP_BASE_URL_NEW}/api/branchimages/${res.data.data[0].branch_image}`
            );
          } else {
            setSelectedImage1("");
          }
          setEditImage(res.data.data[0].branch_image);

          if (res.data.data[1] != null) {
            setSubadmin({
              subAdminEmail: res.data.data[1].subadmin_email,
              id: res.data.data[1].subadmin_id,
            });
          }
          setShow(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleSubAdmin = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/addsubbranch_list/${a_id}`,
        {
          headers: {
            Authorization: `${process.env.REACT_APP_AUTH_KEY}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status == "success") {
          let newlist = response.data.data;
          let options = [];
          if (newlist?.length > 0) {
            for (let i = 0; i < newlist?.length; i = i + 1) {
              let item = {
                value: newlist[i].b_id,
                label: newlist[i].branch_name,
              };
              options.push(item);
            }
          }
          setBranchsList({ id: response.data.data[0].b_id, list: options });
          setBranchList(response.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    setSubAdminOpen(true);
  };

  const handleView = async (id) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL_NEW}/api/getbranchtoedit/${id}`, {
        headers: {
          Authorization: `${process.env.REACT_APP_AUTH_KEY}`,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setEditBranchData({
            branchName: res.data.data[0].branch_name,
            mobileNumber: res.data.data[0].mobilenumber,
            email: res.data.data[0].email,
            address: res.data.data[0].address,
            lats: res.data.data[0].lat,
            long: res.data.data[0].long,
          });
          if (res.data.data[1] != null) {
            setSubadmin({
              subAdminEmail: res.data.data[1].subadmin_email,
              id: res.data.data[1].subadmin_id,
            });
          }
          setView(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleClose = () => {
    setEditBranchData({
      branchName: "",
      email: "",
      mobileNumber: "",
      address: "",
    });
    setSubadmin({ subAdminEmail: "", id: "" });
    setSubAdminOpen(false);
    setHsImage("");
    setImageError(false);
    setSelectedImage("");
    setShow(false);
    setView(false);
    setAdd(false);
    reset();
    reset2();
  };

  const handleShowPass = () => {
    let pwd = document.getElementById("passwordField");
    if (pwd.type === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to delete?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#000000",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`${process.env.REACT_APP_BASE_URL_NEW}/api/deletebranch/${id}`, {
            headers: { Authorization: `${process.env.REACT_APP_AUTH_KEY}` },
          })
          .then((res) => {
            toastmsg(
              "Branch deleted Succesfully",
              toast.POSITION.TOP_CENTER,
              3000
            );

            getBranchList();
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const handleSubAdminDelete = (id) => {
    Swal.fire({
      title: "Do you want to delete the Subadmin?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#000000",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL_NEW}/api/deletesubadmin/${id}/${
              bId && bId
            }`,
            { headers: { Authorization: `${process.env.REACT_APP_AUTH_KEY}` } }
          )
          .then((res) => {
            toastmsg(
              "Subadmin deleted Succesfully",
              toast.POSITION.TOP_CENTER,
              3000
            );
            setShow(false);
            setSubadmin({ subAdminEmail: "", id: "" });
            getBranchList();
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      // setsaver(e.target.files[0]);
      setHsImage(e.target.files[0]);
      setSelectedImage(URL.createObjectURL(e.target.files[0]));
      setImageError(false);
    }
  };

  const imageChange1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      // setsaver(e.target.files[0]);
      setEditImage(e.target.files[0]);
      setSelectedImage1(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleEditBranch = async () => {
    setLoading(true);
    const payload = new FormData();
    payload.append("a_id", a_id);
    payload.append("b_id", bId);
    payload.append("branch_name", editBranchData && editBranchData.branchName);
    payload.append("address", editBranchData && editBranchData.address);
    payload.append(
      "mobilenumber",
      editBranchData && editBranchData.mobileNumber
    );
    // payload.append("email", editBranchData && editBranchData.email);
    payload.append("lat", editBranchData && editBranchData.lats);
    payload.append("long", editBranchData && editBranchData.long);

    editImage == null || editImage == ""
      ? payload.append("bimage", new Blob([branchpic]), "filename.jpg")
      : payload.append("bimage", editImage);
    await axios
      .post(`${process.env.REACT_APP_BASE_URL_NEW}/api/editbranch`, payload, {
        headers: {
          Authorization: `${process.env.REACT_APP_AUTH_KEY}`,
        },
      })
      .then((response) => {
        if (response.data.status == "Success") {
          toastmsg(
            "Branch updated successfully",
            toast.POSITION.TOP_CENTER,
            2000
          );
          setEditBranchData({
            branchName: "",
            mobileNumber: "",
            email: "",
            address: "",
          });
          handleClose();
          getBranchList();
          setLoading(false);
        } else {
          toastmsg(response.data.message, toast.POSITION.TOP_CENTER, 2000);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const handleAddBranch = async (data) => {
    setLoading(true);
    if (selectedImage == "") {
      setImageError(true);
    } else {
      const payload = new FormData();
      payload.append("a_id", a_id);
      payload.append("branch_name", data.branchName);
      payload.append("address", data.address);
      payload.append("mobile", data.mobileNumber);
      // payload.append("email", data.email);
      payload.append("lat", data.lats);
      payload.append("long", data.long);
      payload.append("bimage", hsImage && hsImage);
      await axios
        .post(`${process.env.REACT_APP_BASE_URL_NEW}/api/addbranch`, payload, {
          headers: {
            Authorization: `${process.env.REACT_APP_AUTH_KEY}`,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            toastmsg(response.data.message, toast.POSITION.TOP_CENTER, 2000);
            setSelectedImage("");
            setHsImage("");
            setImageError(false);
            reset2();
            handleClose();
            getBranchList();
            setLoading(false);
          } else {
            toastmsg(
              "Branch Name already Exit please enter Another Name",
              toast.POSITION.TOP_CENTER,
              3000
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          toastmsg(error, toast.POSITION.TOP_CENTER, 3000);
        });
    }
  };

  const handleAddSubAdmin = async (data) => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/addsubadmin`,
        {
          a_id: a_id,
          name: data.name,
          email: data.email,
          password: data.password,
          mobilenumber: data.mobileNumber,
          branch_id: branchsList?.id,
        },
        {
          headers: {
            Authorization: `${process.env.REACT_APP_AUTH_KEY}`,
          },
        }
      )
      .then(async (res) => {
        if (res.data.status == "success") {
          await axios
            .post(
              `${process.env.REACT_APP_BASE_URL_NEW}/api/emailverification`,
              {
                admin_id: res.data.subadmin_id,
                email: data.email,
              },
              { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
            )
            .then((res) => {
              if (res.data.status == "success") {
                toastmsg(res.data.mesage, toast.POSITION.TOP_CENTER, 2000);
                getBranchList();
                handleClose();
                setLoading(false);
                toastmsg(
                  "Subadmin added Succesfully",
                  toast.POSITION.TOP_CENTER,
                  2000
                );
              } else {
                toastmsg(res.data.mesage, toast.POSITION.TOP_CENTER, 2000);
                setLoading(false);
              }
            });
        } else {
          toastmsg(res.data.message, toast.POSITION.TOP_CENTER, 3000);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const handleKeyDown = (e) => {
    // Allow only numbers, backspace and tab
    if (
      ![
        8, 9, 13, 16, 33, 34, 35, 36, 37, 38, 39, 40, 46, 48, 49, 50, 51, 52,
        53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106,
        107, 109, 110, 187, 189, 190, 219, 221,
      ].includes(e.keyCode)
    ) {
      e.preventDefault();
    }
  };

  const handleSelect = (event) => {
    setBranchsList({ ...branchsList, id: event.target.value });
  };

  const buttonClick = (e) => {
    e.preventDefault();
    let hiddenFileInput1 = document.getElementById("hiddenFileInput");
    hiddenFileInput1.click();
  };

  const buttonClick1 = (e) => {
    e.preventDefault();
    let hiddenFileInput = document.getElementById("hiddenFileInput1");
    hiddenFileInput.click();
  };

  const branchesdata = branchData ? (
    branchData.map((item, index) => {
      return (
        <div className=" d-flex crdinfo">
          <div className="img_block">
            <img
              src={
                item.branch_image == null || item.branch_image == ""
                  ? branchpic
                  : `${process.env.REACT_APP_BASE_URL_NEW}/api/branchimages/${item.branch_image}`
              }
              alt="branch img"
              width="120px"
              height="120px"
              style={{
                objectFit: "fill",
                cursor: "pointer",
                width: "150px",
                height: "135.5px",
              }}
              title="view branch"
              onClick={() => handleView(item.b_id)}
            />
          </div>
          <div style={{ width: "130px" }}>
            <div className="edit_delete">
              <img
                src={greenedit}
                alt=""
                onClick={() => handleEdit(item.b_id)}
                style={{
                  width: "16px",
                  height: "16px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
              />
              &nbsp;
              <img
                src={delete1}
                alt=""
                onClick={() => handleDelete(item.b_id)}
                style={{
                  width: "16px",
                  height: "16px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
              />
            </div>
            <div className="branch-body">
              <h5 className="branchname">
                {item.branch_name}
                {item.branch_type == "main" ? " (Main)" : ""}
              </h5>
              <div className="card-content">
                <div className="d-flex align-items-center">
                  <img src={mobile} alt="" />
                  <p className="mobileno">{item.mobilenumber}</p>
                </div>
                <div className="d-flex align-items-center">
                  {/* <img src={email} />
                  <p className="mobileno">{item.branch_email}</p> */}
                </div>
                <h5 className="subadmintitle mt-3">Sub Admin</h5>
                <p className="mobileno" style={{ width: "100px" }}>
                  {item.subadmin_email == null || item.subadmin_email == ""
                    ? "No subadmin"
                    : item.subadmin_email}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    })
  ) : (
    <>No Branches</>
  );

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner={true}
        text="Loading.."
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1100,
          }),
        }}
      >
        <div className="mt-2">
          <div className="">
            <h3 className="page-title">Branches</h3>
          </div>
          <div className="page-header">
            <div className="buttons">
              <div className="d-md-flex align-items-center">
                <div className="drop">
                  <Form>
                    <Form.Group className="mr-3 form_head ">
                      <Form.Control
                        className="form_drop"
                        onChange={(e) => setBranches(e.target.value)}
                        placeholder="Search Branches"
                      />
                      <i
                        className="fa fa-search search_icon"
                        aria-hidden="true"
                      ></i>
                    </Form.Group>
                  </Form>
                </div>

                <div className="deptbtn_respo">
                  <Button className="quat_btn" onClick={handleSubAdmin}>
                    Add Subadmin
                  </Button>
                </div>
                <div className="deptbtn_respo">
                  <Button className="quat_btn" onClick={handleAdd}>
                    Add Branch
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="grid-container" style={{ overflow: "auto" }}>
              {branchesdata}
            </div>
          </div>
          {/* <div className="tableaboveDiv" style={{ overflow: "auto" }}>
        <table className="mt-4 table   table-responsive table-bordered table_style table_ respo table_respo">
          <tr>
            <th>S.NO</th>
            <th>Branch name</th>
            <th>Email ID</th>
            <th>Mobile Number</th>
            <th>Subadmin</th>
            <th>Actions</th>
          </tr>
          <tbody>{branchesdata}</tbody>
        </table>
      </div> */}
          <br />
          {/* <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        onPageChange={handlePageClick}
        containerClassName={"pagination justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      /> */}
          <Modal
            show={subAdminOpen}
            size="lg"
            onHide={handleClose}
            animation={true}
            dialogClassName="my_modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Title className="modal_title">Add Subadmin</Modal.Title>
            <Modal.Body>
              <Form
                autoComplete="off"
                onSubmit={handleSubmit(handleAddSubAdmin)}
              >
                <div className="row align-items-center p-2 ml-2">
                  <div className=" row col-12">
                    <div className="details col-md-6 col-12">
                      <div>
                        <Form.Label>Name</Form.Label>
                      </div>
                      <div>
                        <Form.Control
                          {...register("name", { required: true })}
                          className="brder_align"
                        />
                        {errors.name && errors.name.type === "required" && (
                          <p className="error mt-0">Please Enter Name</p>
                        )}
                      </div>
                    </div>
                    <div className="details col-md-6 col-12">
                      <div>
                        <Form.Label>Tel No.</Form.Label>
                      </div>
                      <div>
                        <Form.Control
                          {...register("mobileNumber", {
                            required: true,
                            minLength: 6,
                            maxLength: 15,
                          })}
                          onKeyDown={handleKeyDown}
                          className="brder_align"
                        />
                        {errors.mobileNumber &&
                          errors.mobileNumber.type === "required" && (
                            <p className="error mt-0">Enter Telephone No</p>
                          )}
                        {errors.mobileNumber &&
                          errors.mobileNumber.type === "minLength" && (
                            <p className="error mt-0">
                              Minimum 6 digit numbers
                            </p>
                          )}
                        {errors.mobileNumber &&
                          errors.mobileNumber.type === "maxLength" && (
                            <p className="error mt-0">
                              Minimum 15 digit numbers
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="details col-md-6 col-12">
                      <div>
                        <Form.Label>Email</Form.Label>
                      </div>
                      <div>
                        <Form.Control
                          {...register("email", {
                            required: true,
                            pattern:
                              /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.(com|in|org|net|us|uk|au|ca|edu|ae)$/,
                          })}
                          className="brder_align"
                        />
                        {errors.email && errors.email.type === "required" && (
                          <p className="error mt-0">Please Enter Email</p>
                        )}
                        {errors.email && errors.email.type === "pattern" && (
                          <p className="error mt-0">Please enter valid Email</p>
                        )}
                      </div>
                    </div>
                    <div className="details col-md-6 col-12 ">
                      <div>
                        <Form.Label>Password</Form.Label>
                      </div>
                      <div className="">
                        <Form.Control
                          id="passwordField"
                          className="form-control brder_align mt-4"
                          style={{ position: "relative" }}
                          type={passwordType}
                          {...register("password", { required: true })}
                        />
                        <span type="button" onClick={handleShowPass}>
                          {passwordType === "password" ? (
                            <FaEyeSlash className="fa_eye1" />
                          ) : (
                            <FaEye className="fa_eye1" />
                          )}
                        </span>
                        {errors.password &&
                          errors.password.type === "required" && (
                            <p className="error mt-0">Enter Password</p>
                          )}
                      </div>
                    </div>

                    <div className="details col-md-6 col-12">
                      <div>
                        <Form.Label>Branch</Form.Label>
                      </div>
                      <div>
                        <select
                          value={branchsList.id != "" && branchsList.id}
                          className=" form-control form_drop drops dept_selct select_rspo"
                          onChange={handleSelect}
                        >
                          {branchsList.list?.length == 0 ? (
                            <option>No Branches</option>
                          ) : (
                            <>
                              {branchsList.list?.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </>
                          )}
                        </select>
                        {/* <Multiselect  onSelect={handleBranch} options={branchsList?.list} displayValue="label" onRemove={handleBranchRemove}/> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal_button">
                  <Button type="submit">Add</Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal
            show={add}
            size="lg"
            onHide={handleClose}
            animation={true}
            dialogClassName="my_modal"
            style={{ overflow: "scroll" }}
          >
            <Modal.Header
              closeButton
              contentClassName="modal_header"
            ></Modal.Header>
            <Modal.Title className="modal_title">Add Branch</Modal.Title>
            <form autoComplete="off">
              <Modal.Body>
                <Form.Group>
                  <div className="row align-items-center p-2 ml-2">
                    <div class=" row col-12">
                      <div className="details col-md-6 col-12">
                        <div>
                          <Form.Label>Branch Name</Form.Label>
                        </div>
                        <div>
                          <Form.Control
                            {...register2("branchName", {
                              required: true,
                              pattern: /^[a-zA-Z0-9 ,.'-\s]+$/,
                            })}
                            className="brder_align"
                          />
                          {errors2.branchName &&
                            errors2.branchName.type === "required" && (
                              <p className="error mt-0">
                                Please enter branch name
                              </p>
                            )}
                          {errors2.branchName &&
                            errors2.branchName.type === "maxLength" && (
                              <p className="error mt-0">
                                Please enter branch name
                              </p>
                            )}
                          {errors2.branchName &&
                            errors2.branchName.type === "pattern" && (
                              <p className="error mt-0">
                                Please enter branch name
                              </p>
                            )}
                        </div>
                      </div>

                      <div className="details col-md-6 col-12">
                        <div>
                          <Form.Label>Tel No.</Form.Label>
                        </div>
                        <div>
                          <Form.Control
                            onKeyDown={handleKeyDown}
                            {...register2("mobileNumber", {
                              required: true,
                              minLength: 6,
                              maxLength: 15,
                            })}
                            className="brder_align"
                          />
                          {errors2.mobileNumber &&
                            errors2.mobileNumber.type === "required" && (
                              <p className="error mt-0">
                                Please enter mobile number
                              </p>
                            )}
                        </div>
                      </div>
                      {/* <div className="details col-md-6 col-12">
                    <div>
                      <Form.Label>Email</Form.Label>
                    </div>
                    <div>
                      <Form.Control {...register2("email", { required: true, pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/ })} className="brder_align" />
                      {errors2.email && errors2.email.type === "required" && <p className="error mt-0">Please enter email</p>}
                      {errors2.email && errors2.email.type === "pattern" && <p className="error mt-0">Please enter valid Email</p>}
                    </div>
                  </div> */}

                      <div className="details col-md-6 col-12">
                        <div>
                          <Form.Label>Address</Form.Label>
                        </div>
                        <div>
                          <Form.Control
                            {...register2("address", { required: true })}
                            as="textarea"
                            rows="3"
                            className="brder_align"
                          />
                          {errors2.address &&
                            errors2.address.type === "required" && (
                              <p className="error mt-0">Please Enter Address</p>
                            )}
                        </div>
                      </div>
                      <div className="details col-md-6 col-12">
                        <div>
                          <Form.Label>latitude</Form.Label>
                        </div>
                        <div>
                          <Form.Control
                            {...register2("lats", {
                              required: true,
                              pattern: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,
                            })}
                            className="brder_align"
                          />
                          {errors2.lats && errors2.lats.type === "required" && (
                            <p className="error mt-0">Please enter Latitude </p>
                          )}
                          {errors2.lats && errors2.lats.type === "pattern" && (
                            <p className="error mt-0">
                              Please enter valid Loatitude
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="details col-md-6 col-12">
                        <div>
                          <Form.Label>longitude</Form.Label>
                        </div>
                        <div>
                          <Form.Control
                            {...register2("long", {
                              required: true,
                              pattern: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,
                            })}
                            className="brder_align"
                          />
                          {errors2.long && errors2.long.type === "required" && (
                            <p className="error mt-0">Please enter Longitude</p>
                          )}
                          {errors2.long && errors2.long.type === "pattern" && (
                            <p className="error mt-0">
                              Please enter valid Longitude
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-12 d-flex align-items-center ">
                        <div className="col-md-8">
                          <img
                            alt=""
                            src={
                              selectedImage != "" ? selectedImage : branchpic
                            }
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <>
                            <input
                              onChange={(e) => imageChange(e)}
                              style={{ display: "none" }}
                              id="hiddenFileInput"
                              accept="image/*"
                              type="file"
                            />
                          </>
                          <button
                            className="upload_sty"
                            onClick={(e) => buttonClick(e)}
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                      <div className="col-md-12  d-flex justify-content-end">
                        {imageError && (
                          <p className="error mt-0">Please upload image </p>
                        )}
                      </div>
                    </div>
                  </div>
                </Form.Group>
                <Modal.Footer className="modal_button">
                  <Button onClick={handleSubmit2(handleAddBranch)}>Add</Button>
                </Modal.Footer>
              </Modal.Body>
            </form>
          </Modal>

          <Modal
            show={show}
            size="lg"
            onHide={handleClose}
            animation={true}
            dialogClassName="my_modal"
            style={{ overflow: "scroll" }}
          >
            <Modal.Header
              closeButton
              contentClassName="modal_header"
            ></Modal.Header>
            <Modal.Title className="modal_title">
              Edit Branch Details
            </Modal.Title>
            <Modal.Body>
              <Form autoComplete="off">
                <Form.Group>
                  <div className="row align-items-center p-2 ml-2">
                    <div class=" row col-12">
                      <div className="details col-md-6 col-12">
                        <div>
                          <Form.Label>Branch Name</Form.Label>
                        </div>
                        <div>
                          <Form.Control
                            className="brder_align"
                            name="branchName"
                            value={editBranchData.branchName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="details col-md-6 col-12">
                        <div>
                          <Form.Label>Tel No</Form.Label>
                        </div>
                        <div>
                          <Form.Control
                            className="brder_align"
                            name="mobileNumber"
                            value={editBranchData.mobileNumber}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {/* <div className="details col-md-6 col-12">
                    <div>
                      <Form.Label>SubAdmin</Form.Label>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Form.Control className="brder_align" name="subAdminEmail" value={subAdmin?.subAdminEmail == "" ? "No Subadmin" : subAdmin.subAdminEmail} readOnly={true} />
                      {subAdmin.subAdminEmail == "" ? (
                        <></>
                      ) : (
                        <img
                          className="ml-2 mt-2 td_img"
                          src={delete1}
                          alt="delete"
                          onClick={() => {
                            handleSubAdminDelete(subAdmin?.id);
                          }}
                        />
                      )}
                    </div>
                    <div></div>
                  </div>
                  {/* <div className="details col-md-6 col-12">
                    <div>
                      <Form.Label>Email</Form.Label>
                    </div>
                    <div>
                      <Form.Control className="brder_align" name="email" value={editBranchData.email} onChange={handleChange} />
                    </div>
                  </div> */}

                      <div className="details col-md-6 col-12">
                        <div>
                          <Form.Label>Address</Form.Label>
                        </div>
                        <div>
                          <Form.Control
                            as="textarea"
                            rows="3"
                            className="brder_align"
                            name="address"
                            value={editBranchData.address}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="details col-md-6 col-12">
                        <div>
                          <Form.Label>latitude</Form.Label>
                        </div>
                        <div>
                          <Form.Control
                            className="brder_align"
                            name="lats"
                            value={editBranchData.lats}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="details col-md-6 col-12">
                        <div>
                          <Form.Label>longitude</Form.Label>
                        </div>
                        <div>
                          <Form.Control
                            className="brder_align"
                            name="long"
                            value={editBranchData.long}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="details col-md-6 col-12">
                        <div>
                          <Form.Label>SubAdmin</Form.Label>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Form.Control
                            className="brder_align"
                            name="subAdminEmail"
                            value={
                              subAdmin?.subAdminEmail == ""
                                ? "No Subadmin"
                                : subAdmin.subAdminEmail
                            }
                            readOnly={true}
                          />
                          {subAdmin.subAdminEmail == "" ? (
                            <></>
                          ) : (
                            <img
                              className="ml-2 mt-2 td_img"
                              src={delete1}
                              alt="delete"
                              onClick={() => {
                                handleSubAdminDelete(subAdmin?.id);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-12 d-flex align-items-center ">
                        <div className="col-md-8">
                          <img
                            alt=""
                            src={
                              selectedImage1 == "" || selectedImage1 == null
                                ? branchpic
                                : selectedImage1
                            }
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <>
                            <input
                              onChange={(e) => imageChange1(e)}
                              style={{ display: "none" }}
                              id="hiddenFileInput1"
                              accept="image/*"
                              type="file"
                            />
                          </>
                          <button
                            className="upload_sty"
                            onClick={(e) => buttonClick1(e)}
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form.Group>
                <Modal.Footer className="modal_button">
                  <Button onClick={handleEditBranch}>Edit</Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          </Modal>
          <Modal
            show={view}
            size="lg"
            onHide={handleClose}
            animation={true}
            dialogClassName="my_modal"
          >
            <Modal.Header
              closeButton
              contentClassName="modal_header"
            ></Modal.Header>
            <Modal.Title className="modal_title">Branch Details</Modal.Title>
            <Modal.Body>
              <Form autoComplete="off">
                <Form.Group>
                  <div className="row align-items-center p-2 ml-2">
                    <div class=" row col-12">
                      <div className="details col-6">
                        <div>Branch Name:</div>
                        <div>{editBranchData.branchName}</div>
                      </div>
                      <div className="details col-6">
                        <div>Tel No:</div>
                        <div>{editBranchData.mobileNumber}</div>
                      </div>
                      {/* <div className="details col-6">
                    <div>Email:</div>
                    <div>{editBranchData.email}</div>
                  </div> */}
                      <div className="details col-6">
                        <div>Address:</div>
                        <div>{editBranchData.address}</div>
                      </div>
                      <div className="details col-sm-6 col-12">
                        <div>SubAdmin</div>
                        <div>
                          {subAdmin?.subAdminEmail == ""
                            ? "No Subadmin"
                            : subAdmin.subAdminEmail}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </LoadingOverlay>
    </>
  );
}

export default Branches;
