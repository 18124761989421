import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import Cancel from "../../assets/images/icons/cancel.svg";
import Submit from "../../assets/images/icons/submit.svg";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { toastmsg } from "../../Helper";
import "./forgot_password.css";
import Spinner from "../shared/Spinner";
import ReCAPTCHA from "react-google-recaptcha";
import Recaptcha from "./recaptcha";

const Forgot = () => {
  const [captcha, setCaptcha] = useState("");
  const [err, setErr] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [msg, setMsg] = useState("");
  const [isallow, setIsAllow] = useState(false);

  let history = useHistory();

  const onSubmit = (data) => {
    const fetch = async () => {
      setIsAllow(true);
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL_NEW}/api/adminforgotpass`,
          { email: data.forget },
          { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
        )
        .then((res) => {
          if (res.data.status == "success") {
            toastmsg(
              "link forward to your registered email",
              toast.POSITION.TOP_CENTER,
              2000
            );
            setIsAllow(false);
            setTimeout(() => {
              history.push("/");
            }, 2000);
          } else {
            toastmsg(res.data.mesage, toast.POSITION.TOP_CENTER, 2000);
            setIsAllow(false);
          }
        });
    };
    if (captcha) {
      fetch();
    } else {
      setErr(true);
    }
  };

  function onChange(value) {
    let captchaval = value;
    setCaptcha(captchaval);
    setErr(false);
    return captchaval;
  }

  const handleCancel = () => {
    history.push("/");
  };

  return (
    <>
      <ToastContainer closeButton={false} hideProgressBar={true} />
      <div className="row h-100">
        <div className="offset-lg-2 col-lg-8">
          <div className="card_respo h-100 d-flex align-items-center">
            <div className="card card1">
              <div className="card card2">
                <div className="card card3">
                  <div className=" mt-4 text-center">
                    <h4 className="header">Forgot Password</h4>
                  </div>
                  <br />
                  <div className="body">
                    <form className="forms" autoComplete="off">
                      <div className="row offset-md-1 align-items-center">
                        <div className="col-md-3">
                          <label className="lable_title">Email ID</label>
                        </div>
                        <div className="col-md-7">
                          <input
                            className="brdr_clr"
                            type="text"
                            {...register("forget", {
                              required: true,
                              pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                            })}
                          />
                          {errors.forget &&
                            errors.forget.type === "required" && (
                              <p className="error mt-0">
                                Field cannot be Empty
                              </p>
                            )}
                          {errors.forget &&
                            errors.forget.type === "pattern" && (
                              <p className="error mt-0">
                                please enter valid Email
                              </p>
                            )}
                          <p className="error">{msg}</p>
                        </div>
                        <div className=" d-flex justify-content-center">
                          <Recaptcha onChange={onChange}></Recaptcha>
                        </div>
                        <p
                          className={
                            err
                              ? "errormessage d-flex justify-content-center"
                              : "errormessage1 "
                          }
                        >
                          Please complete the reCAPTCHA. Verify that you are not
                          a robot.
                        </p>
                      </div>
                    </form>
                    <div className="row pt-4 pb-4">
                      <div className="col-sm-6 col-6 text-center curser_btn">
                        <img src={Cancel} onClick={handleCancel} alt="cancel" />
                      </div>
                      <div className="col-sm-6 col-6 text-center curser_btn">
                        <img
                          src={Submit}
                          alt="submit"
                          onClick={isallow ? "" : handleSubmit(onSubmit)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Forgot;
