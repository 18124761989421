import React, { Component, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import delete1 from "../../assets/images/icons/delete.png";
import edit from "../../assets/images/icons/edit.png";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import axios from "axios";
import "./question.css";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { toastmsg } from "../../Helper";
import { useRef } from "react";
import eye from "../../assets/images/icons/eye.png";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactPaginate from "react-paginate";
import LoadingOverlay from "react-loading-overlay";
//import Pagination from '@material-ui/lab/Pagination';

const Question = () => {
  let btnRef = useRef();
  const [show, setShow] = useState(false);
  // const [departments, setDepartments]=useState([]);
  // const [questions, setQuestions]=useState([]);
  const [iQuestions, setIQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rQuestions, setRQuestions] = useState([]);
  const [iQuestionsCount, setIquestionsCount] = useState();
  const [rQuestionsCount, setRquestionsCount] = useState();
  const [qid, setQid] = useState("");
  const [editquestion, setEditQuestion] = useState({ english: "", arabic: "" });
  const [did, setDid] = useState(1);
  const [change, setChange] = useState(0);
  const [question_type, setQuestionType] = useState("");
  const [branch, setBranch] = useState({ list: [], id: 0 });
  const [department, setDepartment] = useState({ list: [], id: 0 });
  const [pagecountRating, setCountRating] = useState(0);
  const [pagenoRating, setPageNoRating] = useState(0);
  const [pagecountInput, setCountInput] = useState(0);
  const [pagenoInput, setPageNoInput] = useState(0);

  let admin_id = localStorage.getItem("adminId");
  //let DID = ((localStorage.getItem("question")==null)?1:localStorage.getItem("question"))
  //let adminId = ((localStorage.getItem("userdata")==null)?1:localStorage.getItem("userdata"))

  //Branch list
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/branch_list/${admin_id}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )

      .then((res) => {
        let newlist = res.data.data;
        let options = [];
        if (res.data.data) {
          if (newlist?.length > 0) {
            for (let i = 0; i < newlist?.length; i = i + 1) {
              let item = {
                value: newlist[i].b_id,
                label: newlist[i].branch_name,
                type: newlist[i].branch_type,
              };
              options.push(item);
            }
          }
          setBranch({ id: res.data.data[0].b_id, list: options });
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  // department list
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/department_list/${admin_id}/${branch?.id}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )

      .then((res) => {
        let newlist = res.data.data;
        let options = [];
        if (res.data.data) {
          if (newlist?.length > 0) {
            for (let i = 0; i < newlist?.length; i = i + 1) {
              let item = {
                value: newlist[i].d_id,
                label: newlist[i].department,
              };
              options.push(item);
            }
          }
          setDepartment({ id: res.data.data[0].d_id, list: options });
          setLoading(false);
        } else {
          setDepartment({ id: 0, list: [] });
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, [branch.id]);

  //Input Questions
  const inputQuestions = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/questionlist/${department.id}/2/${pagenoInput}/3`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )

      .then((res) => {
        setIQuestions(res.data.data);
        setIquestionsCount(res.data.totalQuestions);
        setCountInput(res.data.totalPages);
        setLoading(false);
      })
      .catch((e) => {
        // alert(e.message);
      });
  };

  //Rating Questions
  const ratingQuestions = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/questionlist/${department.id}/1/${pagenoRating}/3`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        setRQuestions(res.data.data);
        setRquestionsCount(res.data.totalQuestions);
        setCountRating(res.data.totalPages);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    inputQuestions();
    ratingQuestions();
  }, [department.id, pagenoInput, pagenoRating]);

  // const onChangeHandler = (e) => {
  //   localStorage.setItem("question", e.target.value);
  //   setDid(e.target.value);
  // };

  //Edit Question
  const Edit = (id) => {
    setShow(true);
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/getquestiontoedit/${department?.id}/${id}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        let responsedata = res.data.data;
        setEditQuestion({
          english: responsedata[0].questionname_e,
          arabic: responsedata[0].questionname_a,
        });
        setQid(responsedata[0].q_id);
        setQuestionType(responsedata[0].question_type);
        setLoading(false);
      });
  };

  //Delete Question
  const Delete = (id) => {
    Swal.fire({
      title: "Do you want to Delete ?",
      showCancelButton: true,
      // showDenyButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      confirmButtonColor: "#3085d6",
      // denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed || result.value) {
        setLoading(true);
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL_NEW}/api/deletequestion`,
            { q_id: id },
            {
              headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
            }
          )

          .then((res) => {
            toastmsg(res.data.message, toast.POSITION.TOP_CENTER, 2000);
            setLoading(false);
            inputQuestions();
            ratingQuestions();
            setQid("");
            if (rQuestions.length === 1 && pagenoRating > 0) {
              // If it's not the first page, move to the previous page
              handlePageClickRating({ selected: pagenoRating - 1 });
            }
            if (iQuestions.length === 1 && pagenoInput > 0) {
              // If it's not the first page, move to the previous page
              handlePageClickInput({ selected: pagenoInput - 1 });
            }
            // setTimeout(() => {
            //   setChange(change+1)
            //   setQid('')
            // }, 200);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  // Edit Question
  const QuestionEdit = (e) => {
    setEditQuestion({ ...editquestion, [e.target.name]: e.target.value });
  };

  // Check if the input is Arabic
  const isArabic = (text) => {
    // Regular expression to match Arabic characters
    const arabicRegex = /[\u0600-\u06FF\u0750-\u077F]/;
    return arabicRegex.test(text);
  };

  const handleArabicInput = (e) => {
    const inputValue = e.target.value;
    if (isArabic(inputValue) || inputValue === "") {
      // Update state only if the input is Arabic or empty
      setEditQuestion({ ...editquestion, arabic: inputValue });
    }
  };

  //close modal
  const handleClose = () => {
    setQid("");
    setEditQuestion({ english: "", arabic: "" });
    setShow(false);
    setQuestionType(0);
  };

  //Add Question
  const questionApi = () => {
    if (qid === "") {
      // add question

      if (editquestion.english != "") {
        setLoading(true);
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL_NEW}/api/addquestion`,
            {
              english_question: editquestion.english,
              arabic_question: editquestion.arabic,
              department_id: department.id,
              question_type: question_type,
            },
            {
              headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
            }
          )
          .then((res) => {
            if (res.data.status == "success") {
              toastmsg(
                "Question added successfully",
                toast.POSITION.TOP_CENTER,
                3000
              );
              // inputQuestions();
              // ratingQuestions();
              handleClose();
              setLoading(false);
              if (question_type === 1) {
                ratingQuestions();
              } else {
                inputQuestions();
              }
            } else {
              toastmsg(res.data.message, toast.POSITION.TOP_CENTER, 3000);
              setLoading(false);
            }
          });
      } else {
        toastmsg(
          "Please add English a Question",
          toast.POSITION.TOP_CENTER,
          3000
        );
      }
    } else {
      // setShow(false);
      // setQid("");
      // setEditQuestion({ english: "", arabic: "" });
      if (editquestion.english !== "") {
        setLoading(true);
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL_NEW}/api/editquestion`,
            {
              questionname_e: editquestion.english,
              questionname_a: editquestion.arabic,
              q_id: qid,
            },
            {
              headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
            }
          )
          .then((res) => {
            if (res.data.status === "success") {
              toastmsg(
                "Question updated successfully",
                toast.POSITION.TOP_CENTER,
                2000
              );
              handleClose();
              setLoading(false);
              if (question_type === 1) {
                ratingQuestions();
              } else {
                inputQuestions();
              }
            } else {
              toastmsg(res.data.message, toast.POSITION.TOP_CENTER, 2000);
              setLoading(false);
            }
          });
      } else {
        toastmsg(
          "Please add  English  Question",
          toast.POSITION.TOP_CENTER,
          3000
        );
      }
    }
  };

  //Dropdown
  const Dropdown = ({ id, onChange }) => {
    return (
      <div className="mx-2">
        <label>
          {id == "branch" ? (
            <select
              value={branch.id != "" && branch.id}
              className="form-control form_drop drops dept_selct select_rspo"
              onChange={onChange}
            >
              {branch.list?.map((option) => (
                <option value={option.value}>
                  {option.label}
                  {option.type == "main" ? " (Main)" : ""}
                </option>
              ))}
            </select>
          ) : (
            <select
              value={department.id != "" && department.id}
              className="form-control form_drop drops dept_selct select_rspo"
              onChange={onChange}
            >
              {department.list?.length == 0 ? (
                <option>No departments</option>
              ) : (
                <>
                  {department.list?.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </>
              )}
            </select>
          )}
        </label>
      </div>
    );
  };

  //Branch Selection
  const handleChange = (event) => {
    setBranch({ ...branch, id: event.target.value });
    setPageNoRating(0);
    setPageNoInput(0);
  };

  //Department Selection
  const handleChangeDepartment = (event) => {
    setDepartment({ ...department, id: event.target.value });
    setPageNoRating(0);
    setPageNoInput(0);
  };

  //Review Pagination
  const handlePageClickRating = (event) => {
    setPageNoRating(event.selected);
  };

  //Input Pagination
  const handlePageClickInput = (event) => {
    setPageNoInput(event.selected);
  };

  return (
    <React.Fragment>
      <LoadingOverlay
        active={loading}
        spinner={true}
        text="Loading.."
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1100,
          }),
        }}
      >
        <div className="mb-4 d-flex justify-content-between">
          <h3 className="page-title">Questions </h3>
          <div className="d-flex">
            <Dropdown
              id="branch"
              //value={branch.list}
              onChange={handleChange}
            />
            <Dropdown
              id="department"
              //value={branch.list}
              onChange={handleChangeDepartment}
            />
          </div>
        </div>
        <div className="page-header"></div>
        <div className="card">
          <div className="card-body">
            <div>
              <h4 className="text-center">Rating Questions</h4>
              {rQuestionsCount <= 4 ? (
                <>
                  {department.id != 0 && (
                    <Button
                      onClick={(e) => {
                        setShow(true);
                        setQuestionType(1);
                      }}
                      className="quat_btn"
                    >
                      Add Question
                    </Button>
                  )}{" "}
                </>
              ) : (
                <></>
              )}
            </div>

            <br />
            <br />

            {/* {questions.filter(values => values.question_type===1).length>0? questions.filter(values => values.question_type===1) */}
            {rQuestions?.length > 0
              ? rQuestions.map((values, index) => (
                  <div className="row all_questions">
                    <div className="col-sm-2 col-md-2 question">
                      <h6 className="quat">
                        {" "}
                        Question{pagenoRating * 3 + (index + 1)}
                      </h6>
                    </div>
                    <div className="col-sm-8 col-md-8">
                      <Form>
                        <Form.Group>
                          <Form.Label>English</Form.Label>
                          <Form.Control
                            className="brder_align"
                            title={values.questionname_e}
                            value={values.questionname_e}
                            readonly="readonly"
                          />
                        </Form.Group>
                      </Form>
                      <Form>
                        <Form.Group>
                          <Form.Label>Arabic</Form.Label>
                          <Form.Control
                            className="brder_align"
                            title={values.questionname_a}
                            value={values.questionname_a}
                            readonly="readonly"
                          />
                        </Form.Group>
                      </Form>
                    </div>

                    <div className="col-sm-2 col-md-2 icon">
                      <img
                        className="icons_sty"
                        onClick={() => Edit(values.q_id)}
                        src={edit}
                        alt="view"
                      />
                      <img
                        className="ml-3 icons_sty"
                        onClick={() => Delete(values.q_id)}
                        src={delete1}
                        alt="delete"
                      />
                    </div>
                  </div>
                ))
              : "No Questions"}
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pagecountRating}
              forcePage={pagenoRating}
              marginPagesDisplayed={3}
              pageRangeDisplayed={3}
              onPageChange={handlePageClickRating}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
        <div className="card mt-5">
          <div className="card-body">
            <div>
              <h4 className="text-center">Input Questions</h4>
            </div>

            {department.id != 0 && (
              <Button
                onClick={(e) => {
                  setShow(true);
                  setQuestionType(2);
                }}
                className="quat_btn"
              >
                Add Question
              </Button>
            )}
            <br />
            <br />

            {/* {questions.filter(values => values.question_type==2).length>0? questions.filter(values => values.question_type==2) */}
            {iQuestions?.length > 0
              ? iQuestions.map((values, index) => (
                  <div className="row all_questions">
                    <div className="col-sm-2 col-md-2 question">
                      <h6 className="quat">
                        {" "}
                        Question{pagenoInput * 3 + (index + 1)}
                      </h6>
                    </div>
                    <div className="col-sm-8 col-md-8">
                      <Form>
                        <Form.Group>
                          <Form.Label>English</Form.Label>
                          <Form.Control
                            className="brder_align"
                            title={values.questionname_e}
                            value={values.questionname_e}
                            readonly="readonly"
                          />
                        </Form.Group>
                      </Form>
                      <Form>
                        <Form.Group>
                          <Form.Label>Arabic</Form.Label>
                          <Form.Control
                            className="brder_align"
                            title={values.questionname_a}
                            value={values.questionname_a}
                            readonly="readonly"
                          />
                        </Form.Group>
                      </Form>
                    </div>

                    <div className="col-sm-2 col-md-2 icon">
                      <img
                        className=" icons_sty"
                        onClick={() => Edit(values.q_id)}
                        src={edit}
                        alt="edit"
                      />
                      <img
                        className="ml-3 icons_sty"
                        onClick={() => Delete(values.q_id)}
                        src={delete1}
                        alt="delete"
                      />
                    </div>
                  </div>
                ))
              : "No Questions"}
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pagecountInput}
              forcePage={pagenoInput}
              marginPagesDisplayed={3}
              pageRangeDisplayed={3}
              onPageChange={handlePageClickInput}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>

        <Modal show={show} onHide={handleClose} animation={true}>
          <Modal.Header closeButton></Modal.Header>

          <Modal.Title className="modal_title">
            {qid != "" ? "Edit" : "Add"} Question
          </Modal.Title>
          <Modal.Body>
            <Form autoComplete="off">
              <Form.Group>
                <Form.Label>English</Form.Label>
                <Form.Control
                  className="brder_align"
                  name="english"
                  defaultValue={editquestion.english}
                  onInput={QuestionEdit}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Arabic</Form.Label>
                <Form.Control
                  className="brder_align"
                  name="arabic"
                  defaultValue={editquestion.arabic}
                  onInput={QuestionEdit}
                  pattern="[\u0600-\u06FF\u0750-\u077F]*"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {qid == "" ? (
              <Button ref={btnRef} onClick={questionApi}>
                Add
              </Button>
            ) : (
              <Button ref={btnRef} onClick={questionApi}>
                Edit
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </LoadingOverlay>
    </React.Fragment>
  );
};
export default Question;
