import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import {withTranslation} from "react-i18next";
import './App.css'


class App extends Component {
  state={loginCon: true}

 
  componentDidMount() {
    this.onRouteChanged();
    if (this.props.location.pathname.includes('/admin_login') ){
      this.state.loginCon=false;
    }
    if (this.props.location.pathname.includes('/forgot_password') ){
      this.state.loginCon=false;
    }
    if (this.props.location.pathname.includes('/reset_password')) {
      this.state.loginCon=false;
    }
    if (this.props.location.pathname.includes('/verification')) {
      this.state.loginCon=false;
    }
    if (this.props.location.pathname=='/') {
      this.state.loginCon=false;
    }
  }
  render() {

    let navbarComponent=!this.state.isFullPageLayout? <Navbar />:'';
    let sidebarComponent=!this.state.isFullPageLayout? <Sidebar />:'';
    return (
      <div className="container-scroller">
        {navbarComponent}
        <div className="container-fluid page-body-wrapper">
          {sidebarComponent}
          <div className="main-panel">
            <div className="content-wrapper padding_align">
              <div className={this.state.loginCon? 'page_inner':''}>
                <AppRoutes />
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location!==prevProps.location) {
      this.onRouteChanged();
    }
    // alert(this.props.location.pathname)
    if (this.props.location.pathname==='/admin_login') {
      this.state.loginCon=false;
    }
    // if (this.props.location.pathname==='/subAdmin') {
    //   this.state.loginCon=false;
    // }
    else if (this.props.location.pathname==='/forgot_password') {
      this.state.loginCon=false;
    }
    else if (this.props.location.pathname.includes('/reset_password')) {
      this.state.loginCon=false;
    }
    else if (this.props.location.pathname.includes('/verification')) {
      this.state.loginCon=false;
    }
    else if (this.props.location.pathname==='/') {
      this.state.loginCon=false;
    }
    else {
      this.state.loginCon=true;
    }

   
  }

  onRouteChanged() {
    const {i18n}=this.props;
    const body=document.querySelector('body');
    if (this.props.location.pathname==='/layout/RtlLayout') {
      body.classList.add('rtl');
      i18n.changeLanguage('ar');
    }
    else {
      body.classList.remove('rtl')
      i18n.changeLanguage('en');
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes=['/', '/admin_login', '/forgot_password','/verification','/reset_password/:email/:token/:type/', '/user-pages/register-1', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
    for (let i=0; i<fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname===fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      }
      else if (this.props.location.pathname.includes('/reset_password')) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      }
      else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

}

export default withTranslation()(withRouter(App));
