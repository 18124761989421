import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

function Recaptcha({ onChange }) {
  let recaptcha;

  if (window.location.hostname === "localhost") {
    recaptcha = (
      <ReCAPTCHA
        sitekey="6Le1g6QqAAAAAMS8ApRz1MrLou0ZRXbvdizxjcqL"
        onChange={onChange}
      />
    );
  }
  // if (window.location.hostname === "bu1is.krify.com") {
  //   recaptcha = (
  //     <ReCAPTCHA
  //       sitekey="6Le8rDYlAAAAAC6fYbRqYxxGWHnq0xfkFPlGpOxR"
  //       onChange={onChange}
  //     />
  //   );
  // }
  if (window.location.hostname === "gradeusglobal.com") {
    recaptcha = (
      <ReCAPTCHA
        sitekey="6Le1g6QqAAAAAMS8ApRz1MrLou0ZRXbvdizxjcqL"
        onChange={onChange}
      />
    );
  }

  if (window.location.hostname === "admin.gradeusglobal.com") {
    recaptcha = (
      <ReCAPTCHA
        sitekey="6Le1g6QqAAAAAMS8ApRz1MrLou0ZRXbvdizxjcqL"
        onChange={onChange}
      />
    );
  }
  //   if (window.location.hostname === "bu1is.krify.com") {
  //     recaptcha = <ReCAPTCHA sitekey="6LcXsLoeAAAAAOsEaqz9tQLaH9aSE8D1X95k_Djn" onChange={onChange} />;
  //   }

  return <>{recaptcha}</>;
}

export default Recaptcha;
