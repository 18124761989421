import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PrivateRoute, SubPrivateRouting } from "./privateRouting";
// import SubPrivateRoute from './subPrivateRouting'
import Spinner from "../app/shared/Spinner";
import Dashboard from "./dashboard/Dashboard";
import Review from "./review/review";
import Question from "./question/question";
import Department from "./departments/department";
import Complaint from "./complaint/complaint";
import Login from "./login/admin_login";
import Forgot from "./forgotpassword/forgot_password";
import ScreenSaver from "./screenSaver/screen_saver";
import All_Department from "./alldepartments/all_departments";
import Password from "./password/password";
import Branches from "./branches/branches";
import SubReview from "../app/subadmin/Reviews/subreview";
import subDepartment from "./subadmin/Departments/subDepartment";
import subcomplaint from "./subadmin/Complaintmanagement/subcomplaint";
import subQuestion from "./subadmin/subQuestions/subQuestion";
import Saver from "./subadmin/screen saver/subScreen_Saver";
import subDashboard from "./subadmin/Dashboard/subDashboard";
import Verification from "./login/verification";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
// import { isLogin, getUserType } from "./routeIndex";

// Lazy-loaded components
// const Login = lazy(() => import('./login/admin_login'));
// const Verification = lazy(() => import('./login/verification'));
// const Forgot = lazy(() => import('./forgotpassword/forgot_password'));
// const Password = lazy(() => import('./password/password'));
// // // ... other imports

// const Dashboard = lazy(() => import('./dashboard/Dashboard'));
// const Review = lazy(() => import('./review/review'));
// const Question = lazy(() => import('./question/question'));
// const Department = lazy(() => import('./departments/department'));
// const Branches = lazy(() => import('./branches/branches'));
// const Complaint = lazy(() => import('./complaint/complaint'));
// const ScreenSaver = lazy(() => import('./screenSaver/screen_saver'));
// const All_Department = lazy(() => import('./alldepartments/all_departments'));
// const subDashboard = lazy(() => import('./subadmin/Dashboard/subDashboard'));
// const SubReview = lazy(() => import('../app/subadmin/Reviews/subreview'));
// const subDepartment = lazy(() => import('./subadmin/Departments/subDepartment'));
// const subcomplaint = lazy(() => import('./subadmin/Complaintmanagement/subcomplaint'));
// const subQuestion = lazy(() => import('./subadmin/subQuestions/subQuestion'));
// const Saver = lazy(() => import('./subadmin/screen saver/subScreen_Saver'));

class AppRoutes extends Component {

  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/verification" component={Verification} />
          <Route exact path="/forgot_password" component={Forgot} />
          <Route
            exact
            path="/reset_password/:id/:id/:id"
            component={Password}
          />
          {/* Admin Routes */}
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/reviews" component={Review} />
          <PrivateRoute exact path="/questions" component={Question} />
          <PrivateRoute exact path="/department" component={Department} />
          <PrivateRoute exact path="/branches" component={Branches} />
          <PrivateRoute exact path="/management" component={Complaint} />
          <PrivateRoute exact path="/screen_saver" component={ScreenSaver} />
          <PrivateRoute
            exact
            path="/all_departments"
            component={All_Department}
          />

          {/* <Route component={() => <h1>Page not found</h1>} /> */}
          
          
          {/* sub admin Routing */}
          <SubPrivateRouting path="/subDashboard" component={subDashboard} />
          <SubPrivateRouting path="/Subreviews" component={SubReview} />
          <SubPrivateRouting path="/subDepartment" component={subDepartment} />
          <SubPrivateRouting path="/subcomplaint" component={subcomplaint} />
          <SubPrivateRouting path="/subQuestion" component={subQuestion} />
          <SubPrivateRouting path="/subScreenSaver" component={Saver} />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
