import React, { Component, useEffect, useState } from "react";
import { CloseButton, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import delete1 from "../../assets/images/icons/delete.png";
import eye from "../../assets/images/icons/eye.png";
import ReactPaginate from "react-paginate";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { FaFileExcel } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import "jspdf-autotable";
import axios from "axios";
import "./complaint.css";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { IoCalendarSharp } from "react-icons/io5";
import Swal from "sweetalert2";
import exportFromJSON from "export-from-json";
import { saveAs } from "file-saver";
import { toastmsg } from "../../Helper";
import LoadingOverlay from "react-loading-overlay";

const Complaint = (props) => {
  const { state } = useLocation();

  const { branch_id, dep_id } = state || {};
  const initialBranchId = branch_id || 0;
  const intialdepId = dep_id || 0;

  let today = new Date();
  let date = (today.getDate() < 10 ? "0" : "") + today.getDate();
  let requiredDate =
    today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)) + "-" + date;
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [pageno, setPageNo] = useState(0);
  const [pagecount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  // let DID = !localStorage.getItem("complaint") ? did : localStorage.getItem("complaint");
  const [fromdate, setFromdate] = useState(0);
  const [todate, setTodate] = useState(0);
  const [calender, setCalender] = useState("");
  const [show, setShow] = useState(false);
  const [view, setView] = useState({});
  const [branch, setBranch] = useState({ list: [], id: initialBranchId });
  const [department, setDepartment] = useState({ list: [], id: intialdepId });
  const [change, setChange] = useState(0);
  const [complaintsdata, setComplaintsData] = useState([]);

  let limit = 10;

  const a_id = localStorage.getItem("adminId");

  const handlePageClick = (event) => {
    setPageNo(event.selected);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL_NEW}/api/branch_list/${a_id}`, {
        headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
      })
      .then((res) => {
        let newlist = res.data.data;
        let options = [];
        if (res.data.data) {
          if (newlist?.length > 0) {
            for (let i = 0; i < newlist?.length; i = i + 1) {
              let item = {
                value: newlist[i].b_id,
                label: newlist[i].branch_name,
                type: newlist[i].branch_type,
              };
              options.push(item);
            }
          }
          let defaultId = res.data.data.length > 0 ? res.data.data[0].b_id : 0;
          setBranch({ id: initialBranchId || defaultId, list: options });
          setLoading(false);
        }
      })
      .catch((e) => {
        toastmsg(e.message);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/department_list/${a_id}/${branch?.id}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )

      .then((res) => {
        let newlist = res.data.data;
        let options = [];
        if (res.data.data) {
          if (newlist?.length > 0) {
            for (let i = 0; i < newlist?.length; i = i + 1) {
              let item = {
                value: newlist[i].d_id,
                label: newlist[i].department,
              };
              options.push(item);
            }
          }
          let defaultDeptId =
            res.data.data.length > 0 ? res.data.data[0].d_id : 0;
          setDepartment({ id: defaultDeptId, list: options });
          setLoading(false);
        } else {
          setDepartment({ id: 0, list: [] });
          setLoading(false);
        }
      })
      .catch((e) => {
        // alert(e.message);
      });
  }, [branch?.id]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      let serachText = search === "" ? 0.001 : search;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_NEW}/api/complaintslist/${pageno}/${limit}/${serachText}/${department.id}/${fromdate}/${todate}/${a_id}`,
          { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
        );
        const { data: responseData, totalPages } = response.data;
        setData(responseData);
        setPageCount(totalPages);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL_NEW}/api/allcomplaints/${serachText}/${department.id}/${fromdate}/${todate}/excel/${a_id}`,
          { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
        )
        .then((res) => {
          let data = res.data.data;
          setComplaintsData(data || []);
        });
    };
    if (branch?.id !== 0) {
      fetch();
    }
  }, [pageno, search, department.id, calender, change]);


  const changeFromDate = (e) => {
    if (e.target.value) {
      setFromdate(e.target.value + " 00:00:00");
    } else {
      setFromdate(0);
    }
  };

  const changeToDate = (e) => {
    if (e.target.value) {
      setTodate(e.target.value + " 23:59:59");
    } else {
      setTodate(0);
    }
  };

  const calenderSubmit = (e) => {
    if (fromdate !== 0 && todate !== 0) {
      setCalender(fromdate + todate);
    } else {
      setCalender(0);
    }
  };

  const DropdownList = ({ id, onChange }) => {
    let options = [];

    return (
      <div className="mx-2">
        <label>
          {id == "branch" ? (
            <select
              value={branch.id != "" && branch.id}
              className=" mt-2 form-control form_drop drops dept_selct select_rspo"
              onChange={onChange}
            >
              {branch.list?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                  {option.type == "main" ? " (Main)" : ""}
                </option>
              ))}
            </select>
          ) : (
            <select
              value={department.id != "" && department.id}
              className="mt-2 form-control form_drop drops dept_selct select_rspo"
              onChange={onChange}
            >
              {department.list?.length == 0 ? (
                <option>No departments</option>
              ) : (
                <>
                  {department.list?.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </>
              )}
            </select>
          )}
        </label>
      </div>
    );
  };

  // {
  //   pathname: "/management",
  //   state: {
  //     branch_id: 165, // First ID
  //     dep_id: values.dep_id, // Second ID
  //   },
  // }

  const handleChange = (event) => {
    setBranch({ ...branch, id: event.target.value });
    setPageNo(0);
  };

  const handleChangeDepartment = (event) => {
    setDepartment({ ...department, id: event.target.value });
    setPageNo(0);
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (c_id) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL_NEW}/api/viewcomplaint/${c_id}`, {
        headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
      })
      .then((res) => {
        setView(res.data.data);
        setLoading(false);
      });
    setShow(true);
  };

  const remove = (c_id) => {
    Swal.fire({
      title: "Do you want to delete?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#18A7E2",
      customClass: {
        confirmButton: "delete_confirm",
      },
      // denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL_NEW}/api/deletecomplaint/${c_id}`,
            {
              headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
            }
          )
          .then((res) => {
            setChange(change + 1);
            if (data.length === 1 && pageno > 0) {
              // If it's not the first page, move to the previous page
              handlePageClick({ selected: pageno - 1 });
            }
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const excelDownload = () => {
    if (data?.length == 0) {
      alert("No data available");
      return;
    }
    if (complaintsdata?.length > 0) {
      exportFromJSON({
        data: complaintsdata,
        fileName: `Complaints.xls`,
        exportType: exportFromJSON.types.xls,
      });
    }
  };

  const pdfDownload = () => {
    let serachText = search == "" ? 0.001 : search;
    if (data.length == 0) {
      alert("No data available");
      return;
    }
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/allcomplaints/${serachText}/${department.id}/${fromdate}/${todate}/pdf/${a_id}`,
        {
          headers: { Authorization: `${process.env.REACT_APP_AUTH_KEY}` },
          responseType: "blob",
        }
      )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Network response was not ok");
        }
        const blob = new Blob([res.data], { type: "application/pdf" });
        const Url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = Url;
        link.download = "Complaints.pdf";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(Url);
        document.body.removeChild(link);
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <LoadingOverlay
        active={loading}
        spinner={true}
        text="Loading.."
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1100,
          }),
        }}
      >
        <div className="mb-4">
          <h3 className="page-title">Complaint Management </h3>
        </div>
        <div className="page-header">
          <div
            className="d-md-flex align-items-center  complaint_pdf dateDashboard_responsive "
            style={{ flexWrap: "wrap" }}
          >
            <div>
              <div className="d-flex ">
                <div className="d-flex dateDashboard_responsive pl-2">
                  <input
                    type="date"
                    id="fromDate"
                    max={requiredDate}
                    onChange={changeFromDate}
                    className="px-3"
                  />{" "}
                  <span className="picker_to align-self-center">to</span>
                  <input
                    type="date"
                    className="px-3"
                    id="toDate"
                    min={fromdate !== 0 ? fromdate.substr(0, 10) : ""}
                    max={requiredDate}
                    onChange={changeToDate}
                  />
                </div>
                <Button
                  className="calender_submit mx-2"
                  onClick={calenderSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                className="export export_respo"
                variant="success"
                id="dropdown-basic"
              >
                Export
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={data?.length > 0 ? pdfDownload : undefined}
                >
                  {" "}
                  <BsFillFileEarmarkPdfFill className="pdf_icon" />
                  PDF
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={
                    complaintsdata?.length > 0 ? excelDownload : undefined
                  }
                >
                  <FaFileExcel className="excel_icon" />
                  EXCEL
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div style={{ width: "15%" }}>
              <Form>
                <Form.Group className="form_head margin_form ">
                  <Form.Control
                    className="form_drop input_width complaint_search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <i
                    className="fa fa-search search_icon search_btn"
                    aria-hidden="true"
                  ></i>
                </Form.Group>
              </Form>
            </div>
            <DropdownList id="branch" onChange={handleChange} />
            <DropdownList id="department" onChange={handleChangeDepartment} />
          </div>
        </div>
        <div>
          <div className="tableaboveDiv" style={{ overflow: "auto" }}>
            <table className="table table-responsive table-bordered table_ respo table_style">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Complaint ID</th>
                  <th>Description</th>
                  <th>Date</th>
                  <th>Email ID</th>
                  <th>User Name</th>
                  <th>Mobile Number</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data.map((values, index) => (
                    <tr key={index}>
                      <td className="td nowrap_class">
                        {pageno * 10 + index + 1}
                      </td>
                      <td className="td nowrap_class">{values.unique_id}</td>
                      <td
                        className="td nowrap_class"
                        title={values.complaint_msg}
                      >
                        {values.complaint_msg}
                      </td>
                      <td
                        className="td nowrap_class"
                        title={moment(values.created_date).format("YYYY-MM-DD")}
                      >
                        {moment(values.created_date).format("YYYY-MM-DD")}
                      </td>
                      <td className="td nowrap_class" title={values.email}>
                        {values.email}
                      </td>
                      <td className="td nowrap_class" title={values.user_name}>
                        {values.user_name}
                      </td>
                      <td
                        className="td nowrap_class"
                        title={values.phone_number}
                      >
                        {values.phone_number}
                      </td>
                      <td>
                        <img
                          className="ctdImg"
                          onClick={() => handleShow(values.unique_id)}
                          src={eye}
                          alt="view"
                        />
                        <img
                          className="ml-3 td_img"
                          onClick={() => remove(values.unique_id)}
                          src={delete1}
                          alt="delete"
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8}>No data found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <br />

        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pagecount}
          forcePage={pageno}
          marginPagesDisplayed={3}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />

        <Modal show={show} onHide={handleClose} animation={true}>
          <Modal.Title className="modal_title"></Modal.Title>
          <Modal.Body>
            <div onClick={handleClose}>
              <CloseButton />
            </div>
            <div className="mt-4">
              <div className="row">
                <div className="col-md-3 view_color">ID:</div>
                <div className="col-md-8">{view.unique_id}</div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3 view_color">Email ID:</div>
                <div className="col-md-8">{view.email}</div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3 view_color">Description:</div>
                <div className="col-md-8 modaldescription">
                  {view.complaint_msg}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </LoadingOverlay>
    </React.Fragment>
  );
};

export default Complaint;
